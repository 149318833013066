import { all, takeEvery, put, call } from 'redux-saga/effects'
import axios from 'axios'

import { history } from '../../index'

import useAxiosRequest from '../../hooks/useAxiosRequest'

import actions from './actions'

/**
 * @description Login saga function to login user with email and password and set token in local storage and set user data in redux state and redirect to home page
 * @param {object} payload - email and password
 * @param {string} payload.email - email
 * @param {string} payload.password - password
 * @returns {void}
 * @example
 * dispatch({
 *  type: 'user/LOGIN',
 *  payload: {
 *    email: ''
 *    password: ''
 *  }
 * })
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 * @see https://redux-saga.js.org/docs/api/
 * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
 * @see https://redux-saga.js.org/docs/basics/DeclarativeEffects.html
 */
export function* LOGIN({ payload }) {
  const { email, password } = payload
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })

    // New api request
    const newLogin = yield call(
      axios.post,
      process.env.REACT_APP_AUTH_API_URL + '/login',
      {
        email,
        password,
      },
    )

    // API request
    const login = yield call(
      useAxiosRequest,
      '/api/v1/auth/login',
      {
        email,
        password,
      },
      'post',
    )
    // Check if success
    if (login.data && login.data.access_token) {
      const loginResponse = login.data
      localStorage.setItem('apiToken', loginResponse.access_token)
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: true, user: loginResponse.user },
      })
      yield history.push('/')
    } else {
      // Return error
      yield put({ type: 'user/SET_STATE', payload: { loading: false } })
    }
  } catch (e) {
    // Return error
    yield put({ type: 'user/SET_STATE', payload: { loading: false } })
  }
}

/**
 * @description Get current account or fail saga function to get current account data from api and set in redux state or redirect to login page
 * @returns {void}
 * @example
 * dispatch({ type: 'user/GET_CURRENT_ACCOUNT_OR_FAIL' })
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 * @see https://redux-saga.js.org/docs/api/
 * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
 */
export function* GET_CURRENT_ACCOUNT_OR_FAIL() {
  try {
    // Set loading
    yield put({ type: 'user/SET_STATE', payload: { loading: true } })

    // API request to check if user is logged in
    const authCheck = yield call(
      useAxiosRequest,
      '/api/v1/user-details',
      null,
      'get',
      true,
    )
    // Check if success
    if (authCheck.data) {
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: true, user: authCheck.data.user },
      })
    } else {
      // Return error
      yield put({
        type: 'user/SET_STATE',
        payload: { loading: false, authorized: false, user: {} },
      })
    }
  } catch (e) {
    // Return error
    yield put({
      type: 'user/SET_STATE',
      payload: { loading: false, authorized: false, user: {} },
    })
    if (!/^\/marketplace(?=\/|$)/i.test(history.location.pathname)) {
      // yield history.push('/secured/login')
    }
  }
}

/**
 * @description Logout saga function to logout user and remove token from local storage and redirect to login page
 * @returns {void}
 * @example
 * dispatch({ type: 'user/LOGOUT' })
 * @author Rafael Rapizo Nery
 * @since 1.0.0
 * @version 1.0.0
 * @see https://redux-saga.js.org/docs/api/
 * @see https://redux-saga.js.org/docs/basics/UsingSagaHelpers.html
 */
export function* LOGOUT() {
  // API request to logout
  yield call(useAxiosRequest, '/api/logout', null, 'get', true)
  // Remove token from local storage
  localStorage.removeItem('apiToken')
  // Set authorized to false and user to empty object
  yield put({
    type: 'user/SET_STATE',
    payload: { authorized: false, user: {} },
  })
  // Redirect to login page
  yield history.push('/secured/login')
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    GET_CURRENT_ACCOUNT_OR_FAIL(),
  ])
}
